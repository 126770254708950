import { Image } from "react-datocms";

export const TestimonialWrapper = ({ children, dark = false }) => {
    return <div className={"wrapper " + (dark && "dark")}>
        <div className="testimonial">
            {children}
        </div>
        <style jsx>
            {`
            .testimonial {
                width: 100vw;
                padding: var(--landing-page-padding);
                max-width: var(--landing-page-max-width);
                margin: 0 auto;
                min-height: 50vh;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
            .wrapper {
                background: var(--gradient);
            }
            :global(.dark .wrapper) {
                background: var(--background);
                color: #fff;
            }
            @media (max-width: 600px) {
                .testimonial-wrapper{
                    flex-direction:column;
                    gap:0;
                }
                .testimonial {
                    min-height: 40vh;
                    justify-content:center;
                    
                }
            }
        `}
        </style>
    </div>;
};

export const TestimonialCarouselWrapper = ({children}) => {
    return <>
        <div className={"testimonial-content"}>
            {children}
        </div>
        <style jsx>
            {`
            .testimonial-content {
                display: flex;
                height: 100%;
                gap:4rem;
                width:100%;
                padding: 2rem 0;
                min-width: 200px;
                flex: 1 1 500px;
            }
            @media (max-width: 768px) {
                .testimonial-content {
                    width: 100%;
                    height: auto;
                    align-items:start;
                    gap:1rem;
                    flex-direction:column;
                }
            }
        `}
        </style>
    </>;
};

export const TestimonialImage = ({ img }) => {
    return <div className={"animate-in wrapper"}>
        <Image data={img} className="testimonial-img"  usePlaceholder={false}/>
        <style jsx>
            {`
            :global(.testimonial-img){
                background-image: url(${img ? img : "https://xsgames.co/randomusers/avatar.php?g=male"});
                height: 15rem;
                width: 15rem !important;
                max-width:20rem;
                background-size:cover;
                background-repeat: no-repeat;
                border-radius: var(--border-radius);
                place-self: center;
            }
            .wrapper {
                position: relative;
                display: flex;
            }
            @media screen and (max-width: 768px) {
                :global(.testimonial-img) {
                    width: 100% !important;
                    height: auto;
                }
                .wrapper {
                    width: 100%;
                    height: auto;
                }
            }
        `}
        </style>
    </div>;
};

export const TestimonialContent = ({ children }) => {
    return <>
        <div className={"testimonial-content"}>
            {children}
        </div>
        <style jsx>
            {`
            .testimonial-content{
                display:flex;
                flex-direction:column;
                gap:1rem;
                height:fill-content;
                justify-content:center;
                max-width: 50rem;
            }
            @media (max-width: 765px) {
                .testimonial-content{
                    gap:1rem;
                    
                }
                .testimonial-content{
                    display:flex;
                    flex-direction: column-reverse;
                }
            }
            @media (max-width: 600px) {
                .testimonial-content{
                    display:flex;
                    flex-direction: column-reverse;
                }
            }
        `}
        </style>
    </>;
};

export const TestimonialQuote = ({ children }) => {
    return <>
        <h3 className="testimonial-quote animate-in">
            {children}
        </h3>
        <style jsx>
            {`
            .icon {
                color: var(--pink);
            }
            .testimonial-quote{
                color: var(--text);
                font-weight: var(--font-weight-light);
                font-size:var(--font-size-4);
            }
            @media (max-width: 850px) {
                .testimonial-quote{
                    font-size:var(--font-size-3);
                }
            }
        `}
        </style>
    </>;
};

export const TestimonyName = ({ children }) => {
    return <>
        <p className="testimony-name animate-in">
            {children}
        </p>
        <style jsx>
            {`
            .testimony-name{
                margin:0;
                padding:0;
                font-weight: var(--font-weight-bold);
            }
            @media (max-width: 850px) {
                .testimony-name {
                    font-size:var(--font-size-3);
                }
            }
        `}
        </style>
    </>;
};

export const TestimonyPosition = ({ children }) => {
    return <>
        <p className="testimony-position animate-in">
            {children}
        </p>
        <style jsx>
            {`
            .testimony-position{
                margin:0;
                padding-bottom: 0;
                font-size: var(--font-size-2);
            }
            @media (max-width: 850px) {
                .testimony-name{
                    font-size:var(--font-size-2);
                }
            }
        `}
        </style>
    </>;
};

export const TestimonyCompany = ({ children }) => {
    return <>
        <p className="testimony-company animate-in">
            {children}
        </p>
        <style jsx>
            {`
            .testimony-company{
                margin:0;
             }
            @media (max-width: 850px) {
                .testimony-company{
                    font-size:var(--font-size-2);
                }
            }
        `}
        </style>
    </>;
};

export const TestimonialLogo = ({ img }) => {
    return <div>
        <Image data={img} className={" animate-in testimonial-logo "}  usePlaceholder={false}/>
        <style jsx>
            {`
            :global(.testimonial-logo) {
                background-size:contain;
                background-repeat: no-repeat;
                background-position: center;
                height: 100px;
                width: 100px !important;
                max-width:10rem;
                border-radius: var(--border-radius);
                box-shadow: 0 0px 25px -5px rgb(0 0 0/.1),0 8px 10px -6px rgb(0 0 0/.1);
                border-radius: calc(var(--border-radius) * 3);
                border: 3px solid white;
            }
            @media (max-width: 768px) {
                :global(.testimonial-logo) {
                    max-width:100px;
                    width: max-content;
                    height: auto !important;
                }
                div {
                    position: relative;
                    right: 0;
                    bottom: 0;
                    width: max-content;
                }
            }
        `}
        </style>
    </div>;
};
